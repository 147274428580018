import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { ImageNode } from 'gatsby-source-sanity/lib-es5/images/getGatsbyImageProps';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { Button } from '../components/ui/button';

type RoomsTemplateProps = {
  data: {
    sanityRooms: {
      rooms: Array<{
        description: string;
        title: string;
        shareImage: {
          asset: ImageNode;
        };
        content: Array<{
          id: string;
        }>;
        slug: {
          current: string;
        };
      }>;
    };
  };
  pageContext: {
    slug: string;
  };
};

function RoomsTemplate({
  data,
  pageContext,
}: RoomsTemplateProps): React.ReactElement | null {
  const { rooms } = data.sanityRooms;
  const room = rooms.find((r) => r.slug.current === pageContext.slug);
  if (!room) {
    return null;
  }
  return (
    <>
      <SEO title={room.title} description={room.description} />
      <Layout>
        <Hero
          image={
            <GatsbyImage
              image={
                getGatsbyImageData(
                  room.shareImage.asset,
                  { width: 1920, placeholder: 'dominantColor' },
                  { projectId: 'p7tep0zg', dataset: 'production' }
                ) as IGatsbyImageData
              }
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <Button variant="teal">
              <Link to="#contact">Enquire Today</Link>
            </Button>
          }
        />
        {room.content.map((content) => (
          <Content key={content.id} data={content} />
        ))}
        <ContactSection />
      </Layout>
    </>
  );
}

function Content({ data }): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        {data.isReversed ? (
          <SideBySide.ThreeCols>
            <GatsbyImage
              image={
                getGatsbyImageData(
                  data.mainImage.asset,
                  { width: 500, placeholder: 'dominantColor' },
                  { projectId: 'p7tep0zg', dataset: 'production' }
                ) as IGatsbyImageData
              }
              alt=""
              className="w-[20rem] md:w-auto h-auto mx-auto"
            />
          </SideBySide.ThreeCols>
        ) : (
          ''
        )}
        <SideBySide.TwoCols>
          {data.heading && (
            <h2 className="text-6xl">
              <span className="font-semibold">
                {data.heading.replace(/room/gi, ' ')}
              </span>
              Room
            </h2>
          )}

          <SanityBlockContent
            blocks={data._rawCopy}
            renderContainerOnSingleChild
            className="mt-5 prose"
          />
        </SideBySide.TwoCols>

        {!data.isReversed ? (
          <SideBySide.ThreeCols>
            <GatsbyImage
              image={
                getGatsbyImageData(
                  data.mainImage.asset,
                  { width: 500, placeholder: 'dominantColor' },
                  { projectId: 'p7tep0zg', dataset: 'production' }
                ) as IGatsbyImageData
              }
              alt=""
              className="w-[20rem] md:w-auto h-auto mx-auto"
            />
          </SideBySide.ThreeCols>
        ) : (
          ''
        )}
      </SideBySide>
    </div>
  );
}

export const query = graphql`
  query {
    sanityRooms {
      rooms {
        title
        description
        shareImage {
          asset {
            _id
          }
        }
        slug {
          current
        }
        content {
          id: _key
          mainImage {
            asset {
              _id
            }
          }
          _rawCopy
          heading
          isReversed
        }
      }
    }
  }
`;

export default RoomsTemplate;
